import Vue from "vue";
import VueRouter from "vue-router";

import main from "@/router/main";

import zdsAdministrationMembers from "@/modules/zds/administration/members/router";
import zdsAdministrationContacts from "@/modules/zds/administration/contacts/router";
import zdsGenerallySweepDistricts from "@/modules/zds/generally/sweepdistricts/router";
import zdsGenerallyKlartext from "@/modules/zds/generally/klartext/router";
import zdsGenerallyNewsletter from "@/modules/zds/generally/newsletter/router";
import zdsWebsiteDocuments from "@/modules/zds/website/documents/router";
import zdsWebsiteMagazines from "@/modules/zds/website/magazines/router";
import zdsWebsiteTutorials from "@/modules/zds/website/tutorials/router";
import zdsAppWorkingAids from "@/modules/zds/app/workingAids/router";
import zdsAppSessions from "@/modules/zds/app/sessions/router";
import zdsAppConferences from "@/modules/zds/app/conferences/router";
import zdsAppMessages from "@/modules/zds/app/messages/router";
import zdsMediadataImages from "@/modules/zds/mediadata/images/router";
import zdsAdministrationLocalGroups from "@/modules/zds/administration/localGroups/router";
import zdsWebsiteBlogs from "@/modules/zds/website/blogs/router";
import zdsAdministrationLists from "@/modules/zds/administration/lists/router";
import zdsGenerallyTags from "@/modules/zds/generally/tags/router";
import zdsAdministrationLocations from "@/modules/zds/administration/locations/router";
import zdsAdministrationResources from "@/modules/zds/administration/resources/router";
import zdsGenerallyHolidays from "@/modules/zds/generally/holidays/router";
import zdsGenerallyBlockingPeriods from "@/modules/zds/generally/blockingperiods/router";
import zdsAdministrationHappenings from "@/modules/zds/administration/happenings/router";

import sfvGenerallyTags from "@/modules/sfv/generally/tags/router";
import sfvAdministrationTenants from "@/modules/sfv/administration/tenants/router";
import sfvAdministrationLocations from "@/modules/sfv/administration/locations/router";
import sfvAdministrationResources from "@/modules/sfv/administration/resources/router";
import sfvGenerallyHolidays from "@/modules/sfv/generally/holidays/router";
import sfvGenerallyBlockingPeriods from "@/modules/sfv/generally/blockingperiods/router";
import sfvAdministrationHappenings from "@/modules/sfv/administration/happenings/router";

import hwsGenerallyTags from "@/modules/hws/generally/tags/router";
import hwsAdministrationLocations from "@/modules/hws/administration/locations/router";
import hwsAdministrationResources from "@/modules/hws/administration/resources/router";
import hwsGenerallyHolidays from "@/modules/hws/generally/holidays/router";
import hwsGenerallyBlockingPeriods from "@/modules/hws/generally/blockingperiods/router";
import hwsAdministrationHappenings from "@/modules/hws/administration/happenings/router";

Vue.use(VueRouter);

const routes = [
  ...main,
  ...zdsAdministrationMembers,
  ...zdsAdministrationContacts,
  ...zdsGenerallySweepDistricts,
  ...zdsGenerallyKlartext,
  ...zdsGenerallyNewsletter,
  ...zdsWebsiteDocuments,
  ...zdsWebsiteMagazines,
  ...zdsWebsiteTutorials,
  ...zdsAppWorkingAids,
  ...zdsAppSessions,
  ...zdsMediadataImages,
  ...zdsAdministrationLocalGroups,
  ...zdsWebsiteBlogs,
  ...zdsAdministrationLists,
  ...zdsAppConferences,
  ...zdsAppMessages,
  ...zdsGenerallyTags,
  ...zdsAdministrationLocations,
  ...zdsAdministrationResources,
  ...zdsGenerallyHolidays,
  ...zdsGenerallyBlockingPeriods,
  ...zdsAdministrationHappenings,
  ...sfvGenerallyTags,
  ...sfvAdministrationTenants,
  ...sfvAdministrationLocations,
  ...sfvAdministrationResources,
  ...sfvGenerallyHolidays,
  ...sfvGenerallyBlockingPeriods,
  ...sfvAdministrationHappenings,
  ...hwsGenerallyTags,
  ...hwsAdministrationLocations,
  ...hwsAdministrationResources,
  ...hwsGenerallyHolidays,
  ...hwsGenerallyBlockingPeriods,
  ...hwsAdministrationHappenings
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
