import administrationHappenings from "@/modules/hws/administration/happenings/store";
import administrationLocations from "@/modules/hws/administration/locations/store";
import administrationResources from "@/modules/hws/administration/resources/store";
import generallyTags from "@/modules/hws/generally/tags/store";
import generallyHolidays from "@/modules/hws/generally/holidays/store";
import generallyBlockingPeriods from "@/modules/hws/generally/blockingperiods/store";

const state = () => ({
  countryISOCodeList: [{ id: "DE", name: "Deutschland" }],
});

const getters = {};

const actions = {};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    generallyTags,
    administrationLocations,
    administrationResources,
    generallyHolidays,
    generallyBlockingPeriods,
    administrationHappenings
  },
};
