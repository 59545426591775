const state = () => ({
  errors: [],
  loading: true,
  fullLoading: false,
  data: [],
  columns: [],
  tags: [{id:'333',name:'32321'}],
  weekNumber: "",
  year: "",
  month: "",
  total: 0,
  view: "DAY",
  tag: "",
  date: null,
  perPage: "10",
  options: {
    perPage: ["10", "20", "30", "40", "50", "100"],
  },
});

export default state;
