import { ifAuthenticated } from "@/router/auth";

const zdsAdministrationHappenings = [
  {
    path: "/zds/administration/happenings",
    name: "ZDSAdministrationHappenings",
    component: () =>
      import("@/modules/zds/administration/happenings/views/Happenings.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "zds_administration_happenings" },
  },
  {
    path: "/zds/administration/happenings/calendar",
    name: "ZDSAdministrationHappeningsCalendar",
    component: () =>
      import(
        "@/modules/zds/administration/happenings/views/HappeningsCalendar.vue"
      ),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "zds_administration_happenings" },
  },
  {
    path: "/zds/administration/happenings/create",
    name: "ZDSAdministrationHappeningsCreate",
    component: () =>
      import(
        "@/modules/zds/administration/happenings/views/HappeningsCreate.vue"
      ),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "zds_administration_happenings" },
  },
  {
    path: "/zds/administration/happenings/:id/edit",
    name: "ZDSAdministrationHappeningsEdit",
    component: () =>
      import(
        "@/modules/zds/administration/happenings/views/HappeningsEdit.vue"
      ),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "zds_administration_happenings" },
  },
];

export default zdsAdministrationHappenings;
