import state from "./state";
import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

import modal from "@/modules/zds/administration/happenings/store/modal/";
import calendar from "@/modules/zds/administration/happenings/store/calendar/";

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    modal,
    calendar,
  },
};
