import { ifAuthenticated } from "@/router/auth";

const sfvAdministrationHappenings = [
  {
    path: "/sfv/administration/happenings",
    name: "SFVAdministrationHappenings",
    component: () =>
      import("@/modules/sfv/administration/happenings/views/Happenings.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "sfv_administration_happenings" },
  },
  {
    path: "/sfv/administration/happenings/calendar",
    name: "SFVAdministrationHappeningsCalendar",
    component: () =>
      import(
        "@/modules/sfv/administration/happenings/views/HappeningsCalendar.vue"
      ),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "sfv_administration_happenings" },
  },
  {
    path: "/sfv/administration/happenings/create",
    name: "SFVAdministrationHappeningsCreate",
    component: () =>
      import(
        "@/modules/sfv/administration/happenings/views/HappeningsCreate.vue"
      ),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "sfv_administration_happenings" },
  },
  {
    path: "/sfv/administration/happenings/:id/edit",
    name: "SFVAdministrationHappeningsEdit",
    component: () =>
      import(
        "@/modules/sfv/administration/happenings/views/HappeningsEdit.vue"
      ),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "sfv_administration_happenings" },
  },
];

export default sfvAdministrationHappenings;
