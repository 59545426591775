const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setFullLoading(state, loading) {
    state.fullLoading = loading;
  },
  setData(state, payload) {
    state.data = payload.data;
    state.columns = payload.columns;
    state.tags = payload.tags;
    state.total = payload.total;
    state.weekNumber = payload.weekNumber;
    state.year = payload.year;
    state.month = payload.month;
  },
  setErrors(state, errors) {
    state.errors = errors;
  },
  setView(state, value) {
    state.view = value;
  },
  setTag(state, value) {
    state.tag = value;
  },
  setDate(state, value) {
    state.date = value;
  },
  setPerPage(state, value) {
    state.perPage = value;
  },
};

export default mutations;
