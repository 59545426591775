import { ifAuthenticated } from "@/router/auth";

const hwsAdministrationHappenings = [
  {
    path: "/hws/administration/happenings",
    name: "HWSAdministrationHappenings",
    component: () =>
      import("@/modules/hws/administration/happenings/views/Happenings.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "hws_administration_happenings" },
  },
  {
    path: "/hws/administration/happenings/calendar",
    name: "HWSAdministrationHappeningsCalendar",
    component: () =>
      import(
        "@/modules/hws/administration/happenings/views/HappeningsCalendar.vue"
      ),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "hws_administration_happenings" },
  },
  {
    path: "/hws/administration/happenings/create",
    name: "HWSAdministrationHappeningsCreate",
    component: () =>
      import(
        "@/modules/hws/administration/happenings/views/HappeningsCreate.vue"
      ),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "hws_administration_happenings" },
  },
  {
    path: "/hws/administration/happenings/:id/edit",
    name: "HWSAdministrationHappeningsEdit",
    component: () =>
      import(
        "@/modules/hws/administration/happenings/views/HappeningsEdit.vue"
      ),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "hws_administration_happenings" },
  },
];

export default hwsAdministrationHappenings;
