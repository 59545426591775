import api from "@/api";
import _ from "lodash";

const actions = {
  todosByHappening({ commit, dispatch }, payload) {
    const { id, data } = payload;
    return new Promise((resolve, reject) => {
      commit("setLoading", true);
      commit("setErrors", []);
      api
        .get(
          _.join(
            ["/v1/zds/administration/happenings", encodeURI(id), "todos"],
            "/"
          ),
          data
        )
        .then((response) => {
          if (response.data.success) {
            commit("setData", response.data.payload);
            commit("setLoading", false);
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((err) => {
          dispatch("gobalError", err, { root: true });
        });
    });
  },

  done({ commit, dispatch }, payload) {
    const { id, data } = payload;

    return new Promise((resolve, reject) => {
      commit("setInProcess", true);
      commit("setErrors", []);
      api
        .post(
          _.join(
            [
              "/v1/zds/administration/happenings",
              encodeURI(id),
              "todos",
              "done",
            ],
            "/"
          ),
          data
        )
        .then((response) => {
          if (response.data.success) {
            commit("setInProcess", false);
            resolve(response);
          } else {
            commit("setErrors", response.data.payload.errors);
            commit("setInProcess", false);
            reject(response);
          }
        })
        .catch((err) => {
          commit("setInProcess", false);
          dispatch("gobalError", err, { root: true });
        });
    });
  },
  active({ commit, dispatch }, payload) {
    const { id, data } = payload;

    return new Promise((resolve, reject) => {
      commit("setInProcess", true);
      commit("setErrors", []);
      api
        .post(
          _.join(
            [
              "/v1/zds/administration/happenings",
              encodeURI(id),
              "todos",
              "active",
            ],
            "/"
          ),
          data
        )
        .then((response) => {
          if (response.data.success) {
            commit("setInProcess", false);
            resolve(response);
          } else {
            commit("setErrors", response.data.payload.errors);
            commit("setInProcess", false);
            reject(response);
          }
        })
        .catch((err) => {
          commit("setInProcess", false);
          dispatch("gobalError", err, { root: true });
        });
    });
  },
};

export default actions;
