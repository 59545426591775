import addResources from "@/modules/zds/administration/happenings/store/modal/addResources/";
import editResources from "@/modules/zds/administration/happenings/store/modal/editResources/";
import listHolidays from "@/modules/zds/administration/happenings/store/modal/listHolidays/";
import listBlockingPeriods from "@/modules/zds/administration/happenings/store/modal/listBlockingPeriods/";
import addTodosEntry from "@/modules/zds/administration/happenings/store/modal/addTodosEntry/";
import editTodosEntry from "@/modules/zds/administration/happenings/store/modal/editTodosEntry/";
import listDetailTodos from "@/modules/zds/administration/happenings/store/modal/listDetailTodos/";

const state = () => ({});

const getters = {};

const actions = {};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    addResources,
    editResources,
    listHolidays,
    listBlockingPeriods,
    addTodosEntry,
    editTodosEntry,
    listDetailTodos,
  },
};
